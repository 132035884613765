<template>
  <section class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Saída de produtos
        <span id="help-icon" class="icon-box"><v-help-circle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Efetue a saída de produtos no seu estoque, tenha controle de todos os detalhes, lotes, profissionais, pacientes e valores de todas as saídas.
        </b-tooltip>
      </div>

      <div>
        <b-button class="wh-button no-print" variant="outline-primary" outline @click="getOutputsXlsx">
          Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <b-form-group class="form-group">
          <label for="search">Produto</label>
          <b-form-input
            class="placeholder-color"
            id="search"
            autocomplete="off"
            debounce="500"
            placeholder="Buscar produto"
            v-model="query"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group id="field_use_type">
          <label for="type">Uso do produto</label>
            <v-autocomplete
              id="type"
              placeholder="Selecionar"
              debounce="500"
              v-model="type"
              :options="[
                { label: 'Uso em procedimentos', value: 'PROCEDURE' },
                { label: 'Venda', value: 'SALE' },
                { label: 'Descarte', value: 'DISCARD' },
                { label: 'Consumo', value: 'CONSUMPTION' },
                { label: 'Cortesia', value: 'COURTESY' }
              ]"
            />
        </b-form-group>
      </div>

      <div class="col-2">
        <v-periods
          label="Período"
          @select="handlePeriod"
        />
      </div>

      <div class="col-2">
        <label for="batch">Lote</label>
        <v-autocomplete
          v-model="batch"
          placeholder="Selecionar"
          v-bind:options="batches"
        />
      </div>

      <div class="col-4">
        <b-form-group id="field_professional">
          <label for="professional">
            Profissional
          </label>
          <v-autocomplete
            id="professional"
            placeholder="Selecionar"
            debounce="500"
            v-model="professional_id"
            :options="professionals.map(p => ({ label: p.name, value: p.id }))"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <v-patient-input
          required
          v-model="patient"
        />
      </div>

      <div class="col-3">
        <b-form-group id="field_procedure">
          <label for="procedure">
            Procedimento
          </label>
          <v-autocomplete
            id="procedure"
            placeholder="Selecionar"
            debounce="300"
            v-model="procedure_id"
            :options="auxProcedures.map(e => ({ label: e.name, value: e.id }))"
            @typing="filterProcedures"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group id="field_healthPlan">
          <label for="healthPlan">
            Convênio
          </label>
          <v-autocomplete
            id="healthPlan"
            placeholder="Selecionar"
            debounce="500"
            v-model="health_plan_id"
            @typing="debounceGetHealthPlans"
            :options="healthPlans.map(p => ({ label: p.fantasy_name, value: p.id }))"
          />
        </b-form-group>
      </div>
    </div>

    <v-warehouse-outputs-table
      :transactions="transactions"
      :onEdit="onEditClick"
      :onDelete="deleteWarehouseTransaction"
      :loading="loading"
      :hasFilter="hasFilter"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

  </section>
</template>
<script>
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import { EventBus } from '@/utils/eventBus';
import HelpCircle from '@/assets/icons/help-circle.svg'
import Periods from '@/components/General/Periods'
import Autocomplete from '@/components/Autocomplete'
import WarehouseOutputsTable from '@/components/Warehouse/WarehouseOutputsTable'
import PatientInput from '@/components/General/PatientInput'

export default {
  name: 'warehouse-output-tab',
  components: {
    'v-help-circle': HelpCircle,
    'v-warehouse-outputs-table': WarehouseOutputsTable,
    'v-autocomplete': Autocomplete,
    'v-periods': Periods,
    'v-patient-input': PatientInput,
  },
  props: {
    onEditOutputClick: Function,
  },
  created () {
    this.debounceGetHealthPlans = debounce(this.getHealthPlans, 300);
  },
  mounted () {
    this.getWarehouseTransactions();
    this.getProfessionals();
    this.getProcedures();
    this.getHealthPlans('');
    this.getDistinctBatchNumbers();
    EventBus.$on('reloadProductOutputs', () => this.getWarehouseTransactions(true));
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      page: 1,
      limit: 0,
      count: 0,
      query: '',
      type: null,
      start: null,
      end: null,
      batch: null,
      batchQuery: null,
      professional_id: null,
      professionalQuery: null,
      patient: null,
      patientQuery: null,
      procedure_id: null,
      procedureQuery: null,
      health_plan_id: null,
      healthPlanQuery: null,
      transactions: [],
      professionals: [],
      procedures: [],
      auxProcedures: [],
      healthPlans: [],
      batches: [],
      loading: false,
      hasFilter: false,
    }
  },
  methods: {
    handlePeriod(value) {
      this.start = value.start;
      this.end = value.end;
    },
    getWarehouseTransactions(hideLoading) {
      this.loading = true;

      const patientId = this.patient ? this.patient.id : null;

      this.hasFilter = (this.query ||
        this.type || this.start || this.batch || this.professional_id || patientId ||
        this.procedure_id || this.health_plan_id);

      let isLoading = null;
      if (!hideLoading) {
        isLoading = this.$loading.show();
      }
      this.api.getWarehouseOutputTransactions(this.clinic.id, this.page, this.query,
        this.type, this.start, this.end, this.batch, this.professional_id, patientId,
        this.procedure_id, this.health_plan_id)
      .then(res => {
        this.transactions = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
        isLoading && isLoading.hide();
      });
    },
    deleteWarehouseTransaction(transaction) {
      const isLoading = this.$loading.show();
      this.api.deleteWarehouseTransaction(transaction.id)
      .then(() => {
        this.$toast.success('Saída excluida com sucesso');
        this.getWarehouseTransactions();
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    onEditClick(transaction) {
      const output = {
        ...transaction.warehouse_output,
        transactions: [transaction]
      };
      this.onEditOutputClick(output);
    },
    getProfessionals() {
      this.api.showClinicProfessionals(this.clinic.id)
      .then(res => {
        this.professionals = res.data;
      })
      .catch(err => this.$toast.error(err.message));
    },
    getProcedures() {
      this.api.getAllClinicProcedures(this.clinic.id)
      .then(res => {
        this.procedures = res.data;
        this.auxProcedures = res.data;
      })
      .catch(err => this.$toast.error(err.message));
    },
    getHealthPlans(query) {
      this.api.getClinicHealthPlans(this.clinic.id, 1, query)
      .then(res => {
        this.healthPlans = res.data.data.map(clinicHealthPlan => clinicHealthPlan.health_plan);
        this.plans = [];
      })
      .catch(err => this.$toast.error(err.message));
    },
    getDistinctBatchNumbers() {
      this.api.getDistinctBatchNumbers(this.clinic.id)
      .then(res => {
        this.batches = res.data.map(el => ({ label: el.batch, value: el.batch }) );
      })
      .catch(err => this.$toast.error(err.message));
    },
    getOutputsXlsx() {
      const isLoading = this.$loading.show();
      const filters = {
        query: this.query,
        type: this.type, 
        start: this.start,
        end: this.end,
        batch: this.batch, 
        professional_id: this.professional_id,
        patient_id: this.patient ? this.patient.id : null,
        procedure_id: this.procedure_id,
        health_plan_id: this.health_plan_id
      }
      this.api.getWarehouseOutputsExport(this.clinic.id, filters)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'saidas.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    filterProcedures(query) {
      if (!query) {
        this.auxProcedures = this.procedures;
        return;
      }
      this.auxProcedures = this.procedures.filter(p => p.name.toLowerCase().includes(query.toLowerCase()));
    }
  },
  watch: {
    query: function() { this.getWarehouseTransactions(); },
    type: function() { this.getWarehouseTransactions(); },
    start: function() { this.getWarehouseTransactions(); },
    batch: function() { this.getWarehouseTransactions(); },
    professional_id: function() { this.getWarehouseTransactions(); },
    patient: function() { this.getWarehouseTransactions(); },
    procedure_id: function() { this.getWarehouseTransactions(); },
    health_plan_id: function() { this.getWarehouseTransactions(); },
    page: function() { this.getWarehouseTransactions(); },
  }
}
</script>
<style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
    .wh-button {
      margin: 0 0 0 10px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
  }
</style>

