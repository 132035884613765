<template>
  <div>
    <b-table-simple class="p-table" striped>
      <thead>
        <tr>
          <td class="td-header">Saída</td>
          <td class="td-header">Produto</td>
          <td class="td-header">Lote</td>
          <td class="td-header">Uso do produto</td>
          <td class="td-header">Paciente</td>
          <td class="td-header">Profissional</td>
          <td class="td-header">Convênio</td>
          <td class="td-header">Qtde.</td>
          <td class="td-header">Valor total</td>
          <td class="td-header"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transaction, index) in transactions" :key="index">
          <td class="td-body td-simple">
            {{ new Date(transaction.warehouse_output.date).toLocaleDateString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }) }}
          </td>
          <td :class="{'td-body': true, trashed: transaction.product.deleted_at }">
            <b-row>
              <Icon v-if="transaction?.observations?.length" :tooltip="transaction?.observations">
                <HelpCircle class="help-icon" />
              </Icon>
              <span :id="`product_${transaction.warehouse_output.id}`" class="product-name">
                {{ transaction?.product?.name }}
              </span>
              <BatchDetail
                :target="`product_${transaction.warehouse_output.id}`"
                :transaction="transaction"
              />
            </b-row>
          </td>
          <td class="td-body td-simple">{{ transaction.batch }}</td>
          <td class="td-body td-simple">
            <div class="use-type">
              <div v-if="transaction?.warehouse_output?.type === 'PROCEDURE'">
                <Exam v-if="transaction?.warehouse_output?.clinic_procedure?.type === 'EXAM'" class="icon blue-icon" />
                <Procedure v-else-if="transaction?.warehouse_output?.clinic_procedure?.type === 'PROCEDURE'" class="icon blue-icon" />
                <Telemedicine v-else-if="transaction?.warehouse_output?.clinic_procedure?.type === 'TELEMEDICINE'" class="icon blue-icon" />
                <Appointment v-else-if="transaction?.warehouse_output?.clinic_procedure?.type === 'APPOINTMENT'" class="icon blue-icon" />
                <Surgical v-else-if="transaction?.warehouse_output?.clinic_procedure?.type === 'SURGICAL'" class="icon blue-icon" />
              </div>
              <Sale v-else-if="transaction?.warehouse_output?.type === 'SALE'" class="icon" />
              <Trash v-else-if="transaction?.warehouse_output?.type === 'DISCARD'" class="icon trash" />
              <Consumption v-else-if="transaction?.warehouse_output?.type === 'CONSUMPTION'" class="icon" />
              <Courtesy v-else-if="transaction?.warehouse_output?.type === 'COURTESY'" class="icon" />
              <div class="procedure-text">
                {{ parseType(transaction?.warehouse_output?.type, transaction?.warehouse_output?.clinic_procedure) }}
              </div>
            </div>
          </td>
          <td class="td-body td-simple">{{ transaction.warehouse_output.patient ? transaction.warehouse_output.patient.name : '-' }}</td>
          <td class="td-body td-simple">{{ transaction.warehouse_output.person ? transaction.warehouse_output.person.name : '-' }}</td>
          <td class="td-body td-simple">{{ transaction.warehouse_output.health_plan ? transaction.warehouse_output.health_plan.fantasy_name : '-'}}</td>
          <td class="td-body td-simple">{{ normalizeProductNumber(transaction.quantity, 2) }}</td>
          <td class="td-body td-simple">{{ transaction.warehouse_output.type !== 'DISCARD' ? parseNumberToMoney(transaction.sale_price * transaction.quantity) : '-' }}</td>
          <td class="td-body no-print">
            <div v-if="userHasPermission('EstSai3') || userHasPermission('EstSai4')" class="more-with-menu">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  v-can="'EstSai3'"
                  variant="link"
                  v-if="configuration.entries_outputs_editable"
                  @click="onEdit(transaction)"
                >
                  Editar
                </b-button>
                <b-button
                  v-can="'EstSai4'"
                  variant="link remove mb-0"
                  @click="deleteClick(transaction)"
                >
                  Excluir
                </b-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <EmptyTableResults
      emptyMessage="Cadastre uma saída para visualizá-la aqui."
      :loading="loading"
      :length="transactions ? transactions.length : 0"
      :hasFilter="hasFilter"
    />

    <DeleteModal
      type="produto"
      :description="toDelete ? toDelete.product?.name : ''"
      :confirmedDelete="confirmedDelete"
    />
  </div>
</template>
<script>
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { normalizeProductNumber } from '@/utils/product-helper'

export default {
  props: {
    transactions: { type: Array, required: true },
    onEdit: Function,
    onDelete: Function,
    loading: Boolean,
    hasFilter:  { validate: v => true },
  },
  created() {
    this.getWarehouseConfigurations()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      configuration: {},
      toDelete: null,
    }
  },
  components: {
    Appointment: () => import('@/assets/icons/appointment.svg'),
    BatchDetail: () => import('@/components/Warehouse/BatchDetail'),
    DeleteModal: () => import('@/components/General/DeleteModal'),
    EmptyTableResults: () => import('@/components/General/EmptyTableResults'),
    Exam: () => import('@/assets/icons/exam.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    Procedure: () => import('@/assets/icons/procedure.svg'),
    Sale: () => import('@/assets/icons/sale.svg'),
    Surgical: () => import('@/assets/icons/surgical.svg'),
    Telemedicine: () => import('@/assets/icons/telemedicine.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Consumption: () => import('@/assets/icons/consumption.svg'),
    Courtesy: () => import('@/assets/icons/courtesy.svg'),
    Icon: () => import('@/components/General/Icon'),
    HelpCircle: () => import('@/assets/icons/info-circle.svg'),
  },
  methods: {
    userHasPermission,
    parseNumberToMoney,
    normalizeProductNumber,
    parseType(type, procedure) {
      switch(type) {
        case 'PROCEDURE':
          return procedure?.name ?? '-';
        case 'SALE':
          return 'Venda';
        case 'DISCARD':
          return 'Descarte';
        case 'CONSUMPTION':
          return 'Consumo';
        case 'COURTESY':
          return 'Cortesia';
        default:
          return type;
      }
    },
    deleteClick(transaction) {
      this.toDelete = transaction;
      this.$bvModal.show('delete-modal');
    },
    confirmedDelete(confirm) {
      if (confirm) {
        this.onDelete(this.toDelete)
      }
      this.toDelete = null;
      this.$bvModal.hide('delete-modal');
    },
    getWarehouseConfigurations() {
      this.api.getWarehouseConfigurations(this.clinic.id)
      .then(res => {
        this.configuration = res.data;
      })
      .catch(err => this.$toast.error(err.message))
    },
    isExpired(date) {
      const now = new Date();
      const expirationDate = new Date(date);
      return now.getTime() > expirationDate.getTime();
    },
    isToExpire(date, expirationNotice) {
      if (this.isExpired(date)) return false;
      const expirationNoticeDate = new Date();
      expirationNoticeDate.setDate(expirationNoticeDate.getDate() + expirationNotice);
      const expirationDate = new Date(date);
      return expirationNoticeDate.getTime() > expirationDate.getTime();
    },
  }
}
</script>
<style lang="scss" scoped>
  .p-table {
    width: 100%;
    text-align: left;
    margin: 20px 0;

    .td-header {
      font-weight: 700;
      font-size: 14px;
      color: var(--type-active);
      border: none;
      padding: 20px 10px;
    }
    .td-body {
      padding: 20px 10px;
      border-top: 1px dashed var(--neutral-300);
      font-weight: 600;
      font-size: 16px;
      color: var(--type-active);
    }
    .td-simple {
      font-weight: normal !important;
    }
    .procedure-text {
      max-width: 150px;
      margin-left: 10px;
    }
    .number {
      font-family: 'Red Hat Display';
      font-weight: 700;
      color: var(--neutral-500);
    }
    .trashed {
      text-decoration: line-through;
    }
    .more {
      svg {
        stroke: var(--neutral-500);
      }
    }
    .edit-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-500);
    }
    .delete-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--states-error);
    }
    .trash {
      stroke: #FBA59D;
    }
    .icon {
      width: 24px;
      height: 24px;
    }
    .blue-icon {
      fill: #0C1D59;
    }
    .use-type {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
    }
    .product-name {
      cursor: pointer;
    }
    .more-with-menu {
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;

          &.remove {
            color: var(--states-error);
          }

          &.btn-link {
            padding: 12px 80px 12px 16px !important;
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }
  .tooltip-product {
    text-align: left;
    font-size: 12px;

    .batch {
      font-size: 16px;
      font-weight: 600;
      color: var(--type-active);
    }
    .date {
      font-size: 14px;
      font-weight: 600;
      color: var(--type-active);
    }
    .danger {
      color: var(--states-error);
    }
    .warning {
      color: var(--orange);
    }
  }

  .help-icon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    stroke: var(--neutral-500);
  }
</style>
